<template>
	<BlockEcwidStore
		:store-id="storeId"
		:block-id="blockId"
		:show-store="showStore"
		:is-store-loading="isStoreLoading"
		:loading-text="loadingText"
	/>
</template>

<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import BlockEcwidStore from '@zyro-inc/site-modules/components/blocks/ecwid/BlockEcwidStore.vue';
import { useBlockEcwidStore } from '@zyro-inc/site-modules/components/blocks/ecwid/useBlockEcwidStore';

import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';
import { getIsInPreviewIframe } from '@zyro-inc/site-modules/utils/getIsInPreviewIframe';

export default defineComponent({
	components: {
		BlockEcwidStore,
	},

	props: {
		blockId: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
		currentLocale: {
			type: String,
			default: SYSTEM_LOCALE,
		},
	},

	setup(props) {
		const { state } = useStore();

		const {
			storeId,
			showStore,
			isStoreLoading,
			loadingText,
		} = useBlockEcwidStore(props, state);

		// Ecwid doesn't change language on multi-language switch since it needs a hard reload
		if (!!window?.Ecwid?.getStorefrontLang() && window.Ecwid.getStorefrontLang() !== props.currentLocale && !getIsInPreviewIframe()) {
			window.location.reload();
		}

		return {
			storeId,
			showStore,
			isStoreLoading,
			loadingText,
		};
	},
});
</script>
